export default function Logo() {
  return (
    <svg
      width={200}
      preserveAspectRatio="xMidYMid meet"
      data-bbox="-0.004 -0.02 235.834 184.36"
      viewBox="-0.004 -0.02 235.834 184.36"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="img"
      aria-label="Food Banks BC Logo with a fork and spoon with green leaves"
    >
      <g>
        <path
          d="M182.9 131.24h11.66c8.17 0 11.37 4.32 11.37 10.6 0 4.14-2.25 8.11-6.51 9.18v.12c5.21 1.01 7.87 5.03 7.87 10.54 0 7.4-4.79 11.84-12.79 11.84h-11.6v-42.27Zm3.79 18.59h5.98c6.51 0 9.47-2.78 9.47-7.7 0-5.63-2.9-7.7-8.82-7.7h-6.63v15.39Zm0 20.48h7.04c6.57 0 9.77-2.96 9.77-8.64s-3.2-8.64-9.77-8.64h-7.04v17.29Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M235.83 159.18c-.53 9.65-4.97 15.04-12.55 15.04-8.64 0-13.5-6.69-13.5-21.85s4.86-21.85 13.5-21.85 12.14 6.1 12.14 13.03h-3.79c0-5.74-2.9-9.83-8.35-9.83-6.04 0-9.71 4.97-9.71 18.65s3.67 18.65 9.71 18.65c5.33 0 8.35-4.56 8.76-11.84h3.79Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          fill="#737478"
          d="M103.1 151.57h1.94v-9.69h9.74v-1.65h-9.74v-7.36h10.97v-1.65H103.1v20.35z"
          data-color="2"
        ></path>
        <path
          d="M127.64 152c6.38 0 9.58-5.05 9.58-10.6s-3.19-10.6-9.58-10.6-9.61 5.05-9.61 10.6 3.19 10.6 9.61 10.6Zm0-19.55c5.36 0 7.64 4.5 7.64 8.95s-2.28 8.95-7.64 8.95-7.67-4.5-7.67-8.95 2.28-8.95 7.67-8.95Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          d="M159.04 141.4c0-5.56-3.19-10.6-9.58-10.6s-9.61 5.05-9.61 10.6 3.19 10.6 9.61 10.6 9.58-5.05 9.58-10.6Zm-9.58 8.95c-5.39 0-7.67-4.5-7.67-8.95s2.28-8.95 7.67-8.95 7.64 4.5 7.64 8.95-2.28 8.95-7.64 8.95Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          d="M178.45 141.4c0-6.67-3.22-10.03-9.35-10.18h-7.04v20.35h7.04c6.13-.14 9.35-3.51 9.35-10.18ZM164 149.92v-17.05h4.13c5.84-.03 8.38 2.45 8.38 8.52s-2.54 8.55-8.38 8.52H164Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          d="M111.92 164.45v-.05c1.79-.39 3-2.06 3-3.87 0-3.22-2.64-4.23-5.54-4.23h-7.31v17.28h7.31c5.61 0 6.2-3.27 6.2-4.91 0-2.25-1.43-3.92-3.65-4.21Zm-8.2-6.75h5.66c2.57 0 3.9 1.09 3.9 3.24 0 1.62-1.26 2.93-3.9 2.93h-5.66v-6.17Zm5.66 14.47h-5.66v-6.9h5.66c2.88 0 4.55 1.14 4.55 3.34 0 2.76-2.11 3.58-4.55 3.56Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          d="m123.52 156.29-6.97 17.28h1.77l2.08-5.37h7.87l2.11 5.37h1.77l-6.78-17.28h-1.84Zm-2.61 10.5 3.46-8.86 3.34 8.86h-6.8Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          fill="#737478"
          d="M145.88 170.91h-.05l-10.07-14.62h-1.84v17.28h1.65v-14.62h.05l10.06 14.62h1.84v-17.28h-1.64v14.62z"
          data-color="2"
        ></path>
        <path
          fill="#737478"
          d="M164.29 156.29h-2.22l-10.05 9.29v-9.29h-1.64v17.28h1.64v-5.93l3.32-3.07 7.19 9h2.13l-8.09-10.14 7.72-7.14z"
          data-color="2"
        ></path>
        <path
          d="m174.22 164.45-4.21-1.04c-1.4-.34-2.61-1.02-2.61-2.64 0-2.56 2.08-3.44 4.33-3.44 2.44 0 4.62 1.33 4.72 3.97h1.65c.02-3.53-2.98-5.37-6.39-5.37-4.86 0-5.95 3.17-5.95 4.87 0 2.69 1.86 3.65 3.9 4.16l3.85.94c1.6.39 3.56 1.16 3.56 3.12 0 2.52-2.76 3.51-4.57 3.51-3.15 0-5.71-1.09-5.64-4.65h-1.65c-.19 4.21 2.81 6.05 6.9 6.05 6.12 0 6.61-3.75 6.61-4.94 0-2.83-2.03-3.9-4.48-4.55Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          d="M60.26 52.84c4.62-.94 3.06-16.88 3.06-16.88L62.71-.01h-2.39s-.86 33.29-.88 35.97c-.03 5-3.63 4.81-3.81 0-.31-8.29-.07-35.97-.07-35.97h-2.75s-.71 33.71-.75 36.09c-.06 3.81-3.38 5.63-3.81-.13-.39-5.11-.13-35.94-.13-35.94l-3-.03s-.18 33.03-.21 35.97c-.04 4.31-3.23 5.31-3.42-.13-.19-2.88-1.06-35.84-1.06-35.84h-2.34l-.24 35.97s-.97 13.47 1.95 16.39c2.02 2.02 8.64-.2 8.64 5.72 0 0 .02 5.46.04 13.93l-3.81-4.29 3.83 8.84c.02 6.01.03 13.07.05 20.57-1.46-1.56-5.87-6.19-10.45-10.17-5.6-4.87-11.69-15.1-12.18-17.78-.49 1.22 2.19 7.3 6.09 12.66 3.39 4.66 13.89 18.14 16.55 21.55l.03 23.44-.02-.18-7.74-6.09c.5-2.57.47-13.61-28.18-34.82 0 0 24.81 18.66 25.93 32.14.03.37.05.68.08.96l-6.16-4.86-.97.73 17.07 18.94c0 16.76-.03 30.34-.14 32.81-.32 7.19-1.95 17.9 5.48 17.9 6.09 0 3.45-11.46 3.17-18.51-.11-2.68-.61-22.65-1.16-44.65l6.88-12.88-1.35 1.16-5.63 7.38c-.22-8.87-.45-17.9-.66-26.13 1-3.76 7.04-14 7.04-14-3.33 3.1-5.8 7.45-7.14 10.16-.16-6.07-.3-11.57-.42-16.08l6.77-9.41.08-.25-6.95 6.14c-.13-4.6-.22-7.66-.26-8.49-.23-4.4.12-4.26 5.97-5.97Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          d="M83.07 124.63c.43-.98 1.91-4.32 2.69-5.41 3.4-4.77 5.89-9.47 11.56-11.81 4.04-1.67 8.93-3.42 12.62-5.91 3.24-2.19 5.76-4.91 7.73-8.26-.25.42-.34.04-.62.42-4.6 6.28-14.27 9.98-20.25 11.63-7.15 1.96-11.58 9.52-13.25 12.93.32-5.09.47-9.68.49-13.69.88-1.25 5.68-8.07 7.88-10.5-2.85 1.43-6.72 5.4-7.91 6.66-.06-3.92-.24-7.13-.46-9.56 2.08-2.95 7.38-10.39 9.27-12.53 1.62-1.83 3.14-3.75 4.67-5.65 2.03-2.51 4-5.07 5.89-7.68 1.71-2.36 2.23-5.28 2.84-8.06.54-2.48 1.01-4.97 1.48-7.46.1-.54.2-1.08.29-1.62 0 0-2.01 9.86-4.56 14.61-2.56 4.75-16.8 20.46-18.81 23.01-.56.72-.96 1.24-1.25 1.62.4-5.97 4.46-13.08 6.61-16.96 2.44-4.38 7.06-14.61-1.46-29.71-3.65-6.33-8.52-3.41-11.2.49-2.68 3.9-8.43 18.72-.24 30.93 1.07 1.67 2.68 6.09 2.92 9.01.19 2.24.66 13.07.43 23.27l-6.62-8.38 6.45 13.35c-.07 1.35-.15 2.65-.25 3.89-.54 6.54-1.66 19.09-2.55 29.78L70.57 134l6.53 13.33c-.54 6.52-.94 11.8-.99 13.66-.24 9.25 1.22 24.11 6.33 23.14 5.11-.97 6.33-5.85 3.9-15.34-1.83-7.12-4.06-15.34-4.25-27.01 4.09-14.04 7.97-20.78 13.99-25.84-5.89.79-11.62 11.38-13.62 15.46.07-.84.14-1.68.23-2.55.15-1.44.28-2.84.4-4.22Z"
          fill="#737478"
          data-color="2"
        ></path>
        <path
          d="M113.05 48.46c-1.98.52-3.43 1.37-4.51 2.37-.32-6.62-6.15-11.12-6.15-11.12s-.85-1.71-2.6 7.1c-1.57 7.88 4.48 11.14 5.78 11.74.06.14.22.29.7.22 11.3 1.03 13.82-11.02 13.82-11.02s1.1-1.42-7.04.71Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M60.7 79.88c10.95-1.72 10.45-13.73 10.45-13.73s.7-1.6-6.49 2.35-4.74 11.18-4.74 11.18.28.42.78.2Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M64.07 100.97c-5.18 3.69-2.64 9.04-2.64 9.04s.25.3.62.08c8.28-2.31 6.81-11.52 6.81-11.52s.39-1.3-4.79 2.39Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M103.44 91.78c-3.09 6.43 2.29 10.32 2.29 10.32s.41.15.65-.25c6.89-6.75.46-15.01.46-15.01s-.31-1.49-3.41 4.94Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M26.23 87.8c-3.61 6.57 1.83 10.95 1.83 10.95s.42.18.7-.23c7.6-6.69 1.33-15.71 1.33-15.71s-.25-1.58-3.85 4.99Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M36.61 120.1c-9.78-2.61-13.81 7.61-13.81 7.61s-1.18 1.08 6.29.44c7.47-.64 8.09-7.59 8.09-7.59s-.08-.45-.58-.46Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M101.75 68.09c7.1 7.22 15.76.47 15.76.47s1.56-.33-5.2-3.58c-6.76-3.24-10.83 2.42-10.83 2.42s-.16.43.27.69Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M65.7 81.42s-1.35-1.36-.23 7.56 9.48 9.34 9.48 9.34.54-.11.52-.71c2.67-11.85-9.77-16.19-9.77-16.19Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M68.88 116.69s-.73-1.77-3.07 6.91c-.11.4-.19.78-.25 1.16-3.04-1.78-6.1-2.14-6.1-2.14s-1.49-.95 1.32 6.86c2.81 7.81 10.43 6.51 10.43 6.51s.13-.07.23-.21c.14-.06.28-.18.36-.41 6.95-9.96-2.92-18.68-2.92-18.68Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M34.08 53.54s-1.51-1.18.71 7.53c2.21 8.71 10.56 8.11 10.56 8.11s.34-.06.43-.77c1.19-12.09-11.69-14.86-11.69-14.86Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M19.41 91.27s.05-.06.01-.19c.14-.08.3-.25.32-.63 3.03-11.76-9.27-16.48-9.27-16.48s-1.31-1.4-.46 7.55c.26 2.71 1.2 4.65 2.4 6.05C5.63 86.74.16 92.11.16 92.11s-1.82.61 6.68 3.54 12.57-4.38 12.57-4.38Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M32.6 115.18s.37-.04.19-.86c-2.3-11.92-15.43-10.93-15.43-10.93s-1.78-.71 2.81 7.02c4.59 7.73 12.42 4.77 12.42 4.77Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M14.46 70.8c5.28 5.12 10.79 2.34 12.17 1.5.08.04.33.09.72-.38 7.56-8.66-.84-17.89-.84-17.89s-.51-1.74-3.6 6.19c-.87 2.23-.96 4.15-.64 5.77-5.44-3.27-12.12-.91-12.12-.91s-1.8-.21 4.3 5.71Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M127.82 91.17c-3.66-1.15-6.45-.45-8.46.72 3.69-7.17-1.55-14.79-1.55-14.79s-.29-1.89-4.65 5.97c-4.36 7.86 2.13 13.14 2.13 13.14s.04.04.12.06c.06.11.18.25.42.41 9.67 7.35 18.79-2.15 18.79-2.15s1.8-.66-6.78-3.35Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M106.86 109.34c-8.9-1.26-11.51 6.69-11.51 6.69s-.05.52.55.69c10.73 5.69 18.19-5.17 18.19-5.17s1.66-.95-7.24-2.21Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M102.89 79.72s.74-1.77-7.08 2.68c-7.81 4.44-5.02 12.33-5.02 12.33s.15.41.86.21c11.97-2.06 11.23-15.21 11.23-15.21Z"
          fill="#749e2e"
          data-color="1"
        ></path>
        <path
          d="M59.69 63.31s-1.84-6.06 4.22-9.14c6.06-3.08 5.44-1.78 5.44-1.78s.1 9.97-9.02 11.1c-.42.16-.64-.19-.64-.19Z"
          fill="#749e2e"
          data-color="1"
        ></path>
      </g>
    </svg>
  );
}
